<template>
  <div class="progress-content">
    <svg width="150" height="150" viewBox="0 0 150 150">
      <defs>
        <linearGradient id="gradient" x1="100%" y1="0%" x2="0%" y2="0%">
          <stop offset="0%" style="stop-color:#d20051;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#ffa300;stop-opacity:1" />
        </linearGradient>
      </defs>

      <circle cx="75" cy="75" r="70" stroke="#e6e6e6" stroke-width="10" fill="none" />

      <circle
        cx="75"
        cy="75"
        r="70"
        stroke="url(#gradient)"
        stroke-width="10"
        fill="none"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference - (circumference * percentage) / 100"
        transform="rotate(-90 75 75)"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="75" cy="75" r="50" fill="#e6e6e6" />
      <text x="75" y="85" font-size="30" text-anchor="middle" fill="#333">%{{ percentage }}</text>
    </svg>
    <div class="top-products">
      <div class="progress-text">En Çok Satılan 3 Ürünümüzü Yok Satma Oranı</div>
      <img src="~@/assets/rosettes/rosette-modal-info.svg" />
      <div class="tooltip">
        <span
          >Size yapılan ziyaretlerde yoka düşme oranları baz alınarak tahmini olarak
          hesaplanmıştır.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
    },
    circumference: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip {
  top: 105%;
  left: 50%;
  opacity: 0;
  z-index: 1;
  display: flex;
  text-align: center;
  width: 250px;
  height: 100px;
  border-radius: 5px;
  padding: 0 15px;
  position: absolute;
  visibility: hidden;
  flex-direction: column;
  justify-content: center;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ececec;
  transition: opacity 0.2s, visibility 0.2s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.top-products {
  display: flex;
  margin-top: 40px;
  position: relative;

  img {
    margin-left: 5px;
  }

  img:hover + .tooltip {
    visibility: visible;
    opacity: 1;
  }
}
.progress-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
